import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton, LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Treats' Beats | Off-kilter beat licensing"
        ogDescription="Treats' Beats provides off-kilter excluse beat licensing with no clearance needed."
        url="https://treatsbeats.com"
      />
      <PageWrapper color="burgandy">
        <TextBlock
          textPadded
          textLeft={
            <div>
                <h1>Treats' Beats</h1>
                <h2>Off-kilter beats for producers and artists</h2>
                <p>
                  Treats' Beats was created by producer/artist <a href="https://jimmyether.com" target="_blank">Jimmy Ether</a> and the <a href="https://headphonetreats.com" target="_blank">Headphone Treats label</a> to provide beats to producers and artists unlike what's available in typical beat libraries.  We know quality quirk, and make that available to you.
                </p>
                <p>
                  The platform will be launching in early 2023 and is by invite only.
                </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/logo.png" alt="Treats' Beats" />
            </div>
          }
        />
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>If you are an artist or producer who would like an invite, please fill out the form.</h2>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
